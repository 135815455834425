import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { insertOutboundApply, selectBillByParam, insertExitApply, selectLocationByParam, selectApprovalItem } from '@/api/material';
import moment from 'moment';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      // formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const clObjNew = ref([]);
    const formState = reactive({
      theme: "",
      materials: [{
        materialsName: undefined,
        materialsSpecs: undefined,
        materialsNum: undefined,
        supplierName: undefined,
        materialsNameData: [],
        supplierNameData: [],
        materialsSpecsData: [],
        materialsNumData: [],
        clObj: [],
        materialsLocationList: [{
          materialsId: null,
          methodType: 5,
          materialsNum: null,
          physicsLocation: null
        }]
      }],
      exitDirecte: undefined,
      exiStr: '',
      exitTime: undefined,
      itemId: null
    });
    const {
      checkName,
      checkPhone
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      exitTime: [{
        required: true,
        message: '进场时间不能为空',
        trigger: 'change',
        type: 'object'
      }],
      exitDirecte: [{
        required: true,
        message: '材料用途不能为空',
        trigger: 'change'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    }; //添加材料

    const addMaterialInformation = () => {
      formState.materials.push({
        materialsName: undefined,
        materialsSpecs: undefined,
        materialsNum: undefined,
        supplierName: undefined,
        materialsNameData: [],
        supplierNameData: [],
        materialsSpecsData: [],
        maxNum: undefined,
        clObj: [],
        materialsLocationList: [{
          materialsId: null,
          methodType: 5,
          materialsNum: null,
          physicsLocation: null
        }]
      });
    }; //删除材料


    const deleteMaterialInformation = index => {
      formState.materials.splice(index, 1);
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      formRef.value.validate().then(async () => {
        let isOk = true;
        formState.materials.forEach(item => {
          let snum = 0;
          item.materialsLocationList.forEach(ixt => {
            snum += ixt.materialsNum;
          });

          if (item.materialsNum !== snum) {
            _message.error("名称为" + item.materialsName + "、规格为" + item.materialsSpecs + "、供应商为" + ixt.supplierName + "的材料数量与存放位置数量不一样！");

            isOk = false;
          }
        });

        if (!isOk) {
          return;
        }

        const param = {
          theme: formState.theme,
          exitDirecte: formState.exitDirecte === '其他' ? formState.exiStr : formState.exitDirecte,
          exitTime: formState.exitTime.startOf('day').format('YYYY-MM-DD'),
          materials: formState.materials,
          itemId: formState.itemId
        };
        loading.value = true;
        let res = await insertExitApply(param);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          loading.value = false;
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    }; //材料名称查询
    // const clObj = ref([])


    const handleSearch = async (val, index) => {
      const param = {
        materialsName: val,
        supplierName: null
      };
      let res = await selectBillByParam(param);
      console.log(res);

      try {
        formState.materials[index].materialsNameData = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            formState.materials[index].materialsNameData.push({
              value: ixt.materialsName,
              text: ixt.materialsName
            });
          });
          uniqueJsonArray(formState.materials[index].materialsNameData, 'text'); // console.log(formState.materials[index].materialsNameData)
        } else {
          formState.materials[index].materialsNameData.push({
            value: '无数据',
            text: '无数据',
            disabled: true
          });
        }

        clObjNew.value = res.data;
        formState.materials[index].clObj = res.data;
      } catch (error) {
        console.log(error);
      }
    };

    const uniqueJsonArray = (array, key) => {
      for (var i = 0; i < array.length; i++) {
        for (var j = array.length - 1; j > 0; j--) {
          if (array[i][key] == array[j][key] && i != j) {
            array.splice(j, 1);
          }
        }
      }

      return array;
    }; //供应商


    const handleFocusForSup = (materialsName, materialsSpecs, index) => {
      formState.materials[index].supplierNameData = [];
      formState.materials[index].clObj.forEach(item => {
        if (item.materialsName == materialsName && item.materialsSpecs == materialsSpecs) {
          formState.materials[index].supplierNameData.push({
            value: item.supplierName,
            text: item.supplierName
          });
        }

        uniqueJsonArray(formState.materials[index].supplierNameData, 'text');
      });
    }; //规格


    const handleFocusForSp = (val, index) => {
      formState.materials[index].materialsSpecsData = [];
      formState.materials[index].clObj.forEach(item => {
        if (item.materialsName == val) {
          formState.materials[index].materialsSpecsData.push({
            value: item.materialsSpecs,
            text: item.materialsSpecs,
            num: item.inventoryNum
          });
        }
      });
      uniqueJsonArray(formState.materials[index].materialsSpecsData, 'text');
    };

    const handleChange = index => {
      formState.materials[index].materialsSpecs = undefined;
      formState.materials[index].supplierName = undefined;
      formState.materials[index].materialsNum = undefined;
    };

    const handleChange1 = index => {
      formState.materials[index].supplierName = undefined;
      formState.materials[index].materialsNum = undefined;
    };

    const isShowExitStr = ref(false);

    const handleExitDirecte = val => {
      if (val === '其他') {
        isShowExitStr.value = true;
      } else {
        isShowExitStr.value = false;
      }
    }; // 退场信息位置


    const fetching = ref(false);
    const guiWeiArr = ref([]);

    const handleFocusForGuiWei = async val => {
      console.log(val);
      const param = {
        physicsLocationLike: null
      };
      guiWeiArr.value = [];
      fetching.value = true;
      let res = await selectLocationByParam(param);

      try {
        if (res.data.length) {
          res.data.forEach(ixt => {
            if (ixt.materialsName === val.materialsName && ixt.materialsSpecs === val.materialsSpecs && ixt.supplierName === val.supplierName) {
              guiWeiArr.value.push({
                value: ixt.physicsLocation,
                text: ixt.physicsLocation
              });
            }
          });
          uniqueJsonArray(guiWeiArr.value, 'text');
        }

        fetching.value = false;
      } catch (error) {
        fetching.value = false;
        console.log(error);
      }
    }; //添加退场信息位置


    const addMaterialsLocation = index => {
      formState.materials[index].materialsLocationList.push({
        materialsId: null,
        methodType: 5,
        materialsNum: null,
        physicsLocation: null
      });
    }; //删除退场信息位置


    const deleteMaterialsLocation = (index, idx) => {
      formState.materials[index].materialsLocationList.splice(idx, 1);
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 3,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      addMaterialInformation,
      deleteMaterialInformation,
      handleOk,
      loading,
      disabledDate,
      handleSearch,
      handleChange,
      handleFocusForSp,
      handleChange1,
      handleFocusForSup,
      isShowExitStr,
      handleExitDirecte,
      guiWeiArr,
      handleFocusForGuiWei,
      addMaterialsLocation,
      deleteMaterialsLocation,
      fetching,
      projectOptions
    };
  }

});