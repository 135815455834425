import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, DeleteOutlined, ProfileOutlined, PartitionOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { updateToApproval, updateApply, exitUpdateToApproval, exitInsertReviewing, exitUpdateExitApply, selectBillByParam, selectLocationByParam, selectApprovalItem } from '@/api/material';
import printJS from 'print-js';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    DeleteOutlined,
    ProfileOutlined,
    PartitionOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    },
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const objForm = reactive({});
    let formState = reactive({
      content: "",
      theme: '',
      materials: [],
      // approvalProcedureDtos:[],
      approvalDtos: [],
      hyApproachApprovals: [],
      exitDirecte: undefined,
      exiStr: '',
      exitTime: undefined,
      // fileUrl:[],
      contractName: "",
      itemId: null
    });
    let formState1 = reactive({
      signFileurl: [],
      signContent: ""
    }); // console.log('11111',formState)

    watch(() => props.applyDetailData.data, val => {
      if (JSON.stringify(val) !== "{}") {
        formState.theme = props.applyDetailData.data.theme;
        formState.materials = props.applyDetailData.data.materials; // formState.approvalProcedureDtos = props.applyDetailData.data.approvalProcedureDtos

        formState.approvalDtos = props.applyDetailData.data.approvalDtos;
        formState.hyApproachApprovals = props.applyDetailData.data.hyApproachApprovals;
        formState.outConfirm = props.applyDetailData.data.outConfirm; // formState.fileUrl = props.applyDetailData.data.fileUrl ? JSON.parse(props.applyDetailData.data.fileUrl) : []

        formState.contractName = props.applyDetailData.data.contractName;
        formState.exitTime = props.applyDetailData.data.exitTime;
        formState.itemId = props.applyDetailData.data.itemId; // formState.exitDirecte = props.applyDetailData.data.exitDirecte

        switch (props.applyDetailData.data.exitDirecte) {
          case '供应商回收':
          case '材料转场其它项目':
          case '第三方回收':
          case '移交甲方':
            formState.exiStr = "";
            formState.exitDirecte = props.applyDetailData.data.exitDirecte;
            break;

          default:
            formState.exitDirecte = '其他';
            formState.exiStr = props.applyDetailData.data.exitDirecte;
        }
      }
    });
    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state == 2) {
        return false;
      } else if (props.applyDetailData.state == 3) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 18) {
        return true;
      }
    });
    const isDisabledForSp = computed(() => {
      if (props.applyDetailData.state == 2) {
        return true;
      } else if (props.applyDetailData.state == 3) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 18) {
        return true;
      }
    });
    const {
      signFileurl
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      exitTime: [{
        required: true,
        message: '进场时间不能为空',
        trigger: 'change',
        type: 'object'
      }],
      exitDirecte: [{
        required: true,
        message: '材料用途不能为空',
        trigger: 'change'
      }],
      content: [{
        required: true,
        message: '审批意见不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      signContent: [{
        required: true,
        message: '退场说明不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      signFileurl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        trigger: formState1.signFileurl.length == 0
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    }; //添加材料

    const addMaterialInformation = () => {
      formState.materials.push({
        materialsName: undefined,
        materialsSpecs: undefined,
        materialsNum: undefined,
        supplierName: undefined,
        materialsNameData: [],
        supplierNameData: [],
        materialsSpecsData: [],
        maxNum: undefined,
        clObj: []
      });
    }; //删除材料


    const deleteMaterialInformation = index => {
      formState.materials.splice(index, 1);
    }; //获取附件的url


    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState1.signFileurl = val;
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      formRef.value.validate().then(async () => {
        let isOk = true;
        formState.materials.forEach(item => {
          let snum = 0;
          item.materialsLocationList.forEach(ixt => {
            snum += ixt.materialsNum;
          });

          if (item.materialsNum !== snum) {
            _message.error("名称为" + item.materialsName + "、规格为" + item.materialsSpecs + "、供应商为" + ixt.supplierName + "的材料数量与存放位置数量不一样！");

            isOk = false;
          }
        });

        if (!isOk) {
          return;
        }

        const param = {
          applyId: props.applyDetailData.data.applyId,
          theme: formState.theme,
          exitDirecte: formState.exitDirecte,
          exitTime: formState.exitTime.startOf('day').format('YYYY-MM-DD'),
          materials: [],
          itemId: formState.itemId
        };
        formState.materials.forEach(ixt => {
          param.materials.push({
            id: ixt.id ? ixt.id : null,
            materialsName: ixt.materialsName,
            materialsNum: ixt.materialsNum,
            materialsSpecs: ixt.materialsSpecs,
            supplierName: ixt.supplierName,
            materialsLocationList: ixt.materialsLocationList
          });
        });
        const res = await exitUpdateExitApply(param);

        if (res.code == 200) {
          _message.success('修改成功！');

          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error('修改失败！');
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "其他审批人";
      }
    };

    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const handlePassOrReject = num => {
      formRef.value.validate().then(async () => {
        if (!formState.content) {
          _message.error('审批意见不能为空！');

          return;
        }

        const param = {
          applyId: props.applyDetailData.data.applyId,
          content: formState.content,
          state: num,
          belongProject: props.applyDetailData.data.belongProject
        };
        const res = await exitUpdateToApproval(param);

        if (res.code == 200) {
          _message.success('审批成功！');

          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error('审批失败！');
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const detUrl = () => {
      formState1.signFileurl.splice(index, 1);
    }; // const isShowExitStr = ref(false)
    // const handleExitDirecte =(val) =>{
    //   if(val === '其他') {
    //     isShowExitStr.value = true
    //   }else {
    //     isShowExitStr.value = false
    //   }
    // }


    const isShowExitStr = computed(() => {
      if (formState.exitDirecte === '其他') {
        return true;
      } else {
        return false;
      }
    });
    const formRef1 = ref();

    const handleExit = async () => {
      if (!formState1.signContent) {
        _message.error('退场说明不能为空！');

        return;
      }

      if (formState1.signFileurl.length == 0) {
        _message.error('退场凭证不能为空！');

        return;
      }

      const param = {
        applyId: props.applyDetailData.data.applyId,
        signContent: formState1.signContent,
        signFileurl: JSON.stringify(formState1.signFileurl)
      };
      const res = await exitInsertReviewing(param);

      if (res.code == 200) {
        _message.success('退场成功！');

        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
      } else {
        _message.error('退场失败！');
      }
    }; //材料名称查询
    // const clObj = ref([])


    const handleSearch = async (val, index) => {
      const param = {
        materialsName: val,
        supplierName: null
      };
      let res = await selectBillByParam(param);
      console.log(res);

      try {
        formState.materials[index].materialsNameData = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            formState.materials[index].materialsNameData.push({
              value: ixt.materialsName,
              text: ixt.materialsName
            });
          });
          uniqueJsonArray(formState.materials[index].materialsNameData, 'text'); // console.log(formState.materials[index].materialsNameData)
        } else {
          formState.materials[index].materialsNameData.push({
            value: '无数据',
            text: '无数据',
            disabled: true
          });
        }

        formState.materials[index].clObj = res.data;
      } catch (error) {
        console.log(error);
      }
    };

    const uniqueJsonArray = (array, key) => {
      for (var i = 0; i < array.length; i++) {
        for (var j = array.length - 1; j > 0; j--) {
          if (array[i][key] == array[j][key] && i != j) {
            array.splice(j, 1);
          }
        }
      }

      return array;
    }; //供应商


    const handleFocusForSup = (materialsName, materialsSpecs, index) => {
      formState.materials[index].supplierNameData = [];
      formState.materials[index].clObj.forEach(item => {
        if (item.materialsName == materialsName && item.materialsSpecs == materialsSpecs) {
          formState.materials[index].supplierNameData.push({
            value: item.supplierName,
            text: item.supplierName
          });
        }

        uniqueJsonArray(formState.materials[index].supplierNameData, 'text');
      });
    }; //规格


    const handleFocusForSp = (val, index) => {
      formState.materials[index].materialsSpecsData = [];
      formState.materials[index].clObj.forEach(item => {
        if (item.materialsName == val) {
          formState.materials[index].materialsSpecsData.push({
            value: item.materialsSpecs,
            text: item.materialsSpecs,
            num: item.inventoryNum
          });
        }
      });
      uniqueJsonArray(formState.materials[index].materialsSpecsData, 'text');
    };

    const handleChange = index => {
      formState.materials[index].materialsSpecs = undefined;
      formState.materials[index].supplierName = undefined;
      formState.materials[index].materialsNum = undefined;
    };

    const handleChange1 = index => {
      formState.materials[index].supplierName = undefined;
      formState.materials[index].materialsNum = undefined;
    }; //设置是否可编辑


    const setDisabled = (val1, val2) => {
      if (val1) {
        return true;
      } else {
        if (val2) {
          return false;
        } else {
          return true;
        }
      }
    }; //打印


    const handlePrint = () => {
      let data = [];

      for (let i = 0; i < formState.materials.length; i++) {
        data.push({
          materialsName: formState.materials[i].materialsName,
          materialsSpecs: formState.materials[i].materialsSpecs,
          supplierName: formState.materials[i].supplierName,
          materialsNum: formState.materials[i].materialsNum
        });
      }

      printJS({
        printable: data,
        properties: [{
          field: 'materialsName',
          displayName: '材料名称',
          columnSize: 1
        }, {
          field: 'materialsSpecs',
          displayName: '规格',
          columnSize: 1
        }, {
          field: 'supplierName',
          displayName: '供应商',
          columnSize: 1
        }, {
          field: 'materialsNum',
          displayName: '数量',
          columnSize: 1
        }],
        type: 'json',
        header: `退场材料清单--${formState.theme}`,
        // 样式设置
        headerStyle: "font-size: 24px;",
        gridStyle: 'border: 1px solid #3971A5;',
        gridHeaderStyle: 'color: red;  border: 1px solid #3971A5;'
      });
    }; //下载


    const handleDownExcel = () => {
      let tableData = [];

      for (let i = 0; i < formState.materials.length; i++) {
        tableData.push({
          materialsName: formState.materials[i].materialsName,
          materialsSpecs: formState.materials[i].materialsSpecs,
          supplierName: formState.materials[i].supplierName,
          materialsNum: formState.materials[i].materialsNum
        });
      }

      require.ensure([], () => {
        const {
          export_json_to_excel
        } = require("@/assets/excel/Export2Excel");

        const tHeader = ["材料名称", "规格", "供应商", "数量"]; // 导出的excel表头名信息

        const filterVal = ["materialsName", "materialsSpecs", "supplierName", "materialsNum"]; // 导出的excel表头字段名，需要导出表格字段名

        const list = tableData;
        const data = formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, `退场材料清单--${formState.theme}`); // 导出的表格名称，根据需要自己命名
      });
    }; //格式转换


    const formatJson = (filterVal, jsonData) => {
      return jsonData.map(v => filterVal.map(j => v[j]));
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    }; // 退场信息位置


    const guiWeiArr = ref([]);

    const handleFocusForGuiWei = async () => {
      const param = {
        physicsLocationLike: null
      };
      let res = await selectLocationByParam(param);

      try {
        guiWeiArr.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            guiWeiArr.value.push({
              value: ixt.physicsLocation,
              text: ixt.physicsLocation
            });
          });
        }

        uniqueJsonArray(guiWeiArr.value, 'text');
      } catch (error) {
        console.log(error);
      }
    }; //添加退场信息位置


    const addMaterialsLocation = index => {
      formState.materials[index].materialsLocationList.push({
        materialsId: null,
        methodType: 5,
        materialsNum: null,
        physicsLocation: null
      });
    }; //删除退场信息位置


    const deleteMaterialsLocation = (index, idx) => {
      formState.materials[index].materialsLocationList.splice(idx, 1);
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 3,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      addMaterialInformation,
      deleteMaterialInformation,
      handleOk,
      loading,
      getAdjuncts,
      isDisabledForCl,
      isDisabledForSp,
      setRoleType,
      getCurrent,
      disabledDate,
      handlePassOrReject,
      detUrl,
      isShowExitStr,
      // handleExitDirecte,
      formState1,
      formRef1,
      handleExit,
      handleSearch,
      handleChange,
      setDisabled,
      handleFocusForSp,
      handleChange1,
      handleFocusForSup,
      handlePrint,
      handleDownExcel,
      setStatus,
      guiWeiArr,
      handleFocusForGuiWei,
      addMaterialsLocation,
      deleteMaterialsLocation,
      projectOptions
    };
  }

});